export const getHashParams = () => {
  var hash = window.location.hash.substr(1);
  var result = hash.split('&').reduce(function(result, item) {
    var parts = item.split('=');
    result[parts[0]] = parts[1];
    return result;
  }, {});
  return result;
};

export const serialize = obj => {
  var str = [];
  for (var p in obj) {
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(JSON.stringify(obj[p])));
    }
  }
  return str.join('&');
};

export const randomString = length => {
  var charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~';
  let result = '';

  while (length > 0) {
    var bytes = new Uint8Array(16);
    var random = window.crypto.getRandomValues(bytes);

    random.forEach(function(c) {
      if (length === 0) {
        return;
      }
      if (c < charset.length) {
        result += charset[c];
        length--;
      }
    });
  }
  return result;
};

export const objify = (objList, keyKey, valueKey) => {
  let obj = {};
  objList.map(el => {
    if (!valueKey) {
      obj[el[keyKey]] = el;
    } else {
      obj[el[keyKey]] = el[valueKey];
    }
  });
  return obj;
};

export const gasend = ev => () => {
  if (typeof window !== 'undefined') {
    console.log('ga: ' + ev);
    window.gtag('event', ev);
  }
};
