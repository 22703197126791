import React from 'react';
import Header from './Header';
import Footer from './Footer';

import '../style/_common.scss';

export default class StandardPage extends React.Component {
  componentDidMount() {
    if (window.location.hash) {
      location.hash = window.location.hash;
    }
  }
  render() {
    return (
      <div>
        <Header />
        {this.props.children}
        <Footer />
      </div>
    );
  }
}
