import React from 'react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  PinterestShareButton,
  RedditShareButton,
  EmailShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  PinterestIcon,
  RedditIcon,
  EmailIcon
} from 'react-share';

export default function ShareRow(props) {
  const url = typeof window != 'undefined' ? window.location.href : 'https://dpcnation.org';
  const title =
    typeof window != 'undefined' ? window.document.title : 'What is Direct Primary Care?';

  return (
    <div
      className="wide col js as"
      style={
        props.label
          ? {
              padding: '8px 12px',
              borderLeft: '2px solid grey',
              marginBottom: '8px',
              backgroundColor: '#00000008'
            }
          : {}
      }>
      {props.label && (
        <label className="" style={{ fontSize: '12pt' }}>
          Know someone who could benefit from DPC? Tell them!
        </label>
      )}
      <div className="row js ac mt8 mb4">
        <FacebookShareButton url={url} className="clickable">
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <div style={{ width: '6px' }} />
        <LinkedinShareButton url={url} title={title} className="clickable">
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
        <div style={{ width: '6px' }} />
        <TwitterShareButton url={url} title={title} hashtags={['#dpcnation']} className="clickable">
          <TwitterIcon size={32} round />
        </TwitterShareButton>
        <div style={{ width: '6px' }} />
        <PinterestShareButton
          url={url}
          media={'https://dpcnation.org/img/banner.png'}
          className="clickable">
          <PinterestIcon size={32} round />
        </PinterestShareButton>
        <div style={{ width: '6px' }} />
        <RedditShareButton url={url} className="clickable">
          <RedditIcon size={32} round />
        </RedditShareButton>
        <div style={{ width: '6px' }} />
        <EmailShareButton url={url} subject={title} className="clickable">
          <EmailIcon size={32} round />
        </EmailShareButton>
      </div>
    </div>
  );
}
