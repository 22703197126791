import React from 'react';
import * as sassVars from '../style/_vars.scss';
import { Icon, Drawer } from 'antd';
import { gasend } from '../util';

class Header extends React.Component {
  state = {
    menu: false
  };
  render() {
    const headerItem = 'fs12 mh2 btn';

    const menuItems = [
      <a
        href="/"
        className={`${headerItem} colorplain sm-hide`}
        key="2"
        style={{ display: 'block' }}>
        What is DPC
      </a>,

      <a
        href="/essays/"
        className={`${headerItem} colorplain sm-hide`}
        key="3"
        style={{ display: 'block' }}>
        Essays
      </a>,
      // <a href="/essays" className={`${headerItem} colorplain sm-hide`} key="1">
      //   Essays
      // </a>,
      <a
        href="/faq/"
        className={`${headerItem} colorplain sm-hide`}
        key="4"
        style={{ display: 'block' }}>
        FAQ
      </a>,
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://mapper.dpcfrontier.com"
        key="5"
        className={`${headerItem} coloroutline `}
        style={{ border: `2px solid ${sassVars.mainColor}` }}
        onClick={gasend('find_a_practice')}>
        Join a Practice
      </a>
    ];
    return (
      <div
        className={'wide row jb ac pv8 ph16'}
        style={{
          position: 'fixed',
          top: '0px',
          left: '0px',
          backgroundColor: '#ffffffce',
          zIndex: 1
        }}>
        <a href="/" className="clickable">
          <img
            style={{ width: '150px', maxWidth: '30vw' }}
            src="/img/logo_small.png"
            alt="DPC Nation logo"
            title="DPC Nation logo"
          />
        </a>
        <div className="md-hide lg-hide xl-hide">
          <Icon
            style={{ fontSize: '25px', color: sassVars.mainColor }}
            type={this.state.menu ? 'menu-unfold' : 'menu-fold'}
            onClick={() => {
              this.setState({ menu: !this.state.menu });
            }}
          />
        </div>
        <Drawer
          title="Menu"
          placement="right"
          closable={true}
          onClose={() => {
            this.setState({ menu: false });
          }}
          visible={this.state.menu}>
          <div className="col ae js">{menuItems}</div>
        </Drawer>
        <div className="row fs11 sm-hide">{menuItems}</div>
      </div>
    );
  }
}

export default Header;
