import React from 'react';
import StandardPage from '../../components/StandardPage';

export default function SurgeryCenters() {
  return (
    <StandardPage>
      <div className="col ac jc wrap" style={{ backgroundColor: 'white', padding: '100px 0px' }}>
        <div className="wide readable col js as">
          <h1 className="subsubtitle">List of surgery centers with transparent prices</h1>
          <br />
          <p>
            <a href="http://www.surgerycenterok.com/" target="_blank" rel="noopener noreferrer">
              Surgery Center of Oklahoma
            </a>
            &nbsp;(Oklahoma City, OK)
          </p>
          <p>
            <a
              href="https://www.affordableherniasurgery.com/"
              target="_blank"
              rel="noopener noreferrer">
              Affordable Hernia Surgery
            </a>{' '}
            (Rockville, MD)
          </p>
          <p>
            <a
              href="http://www.gsmc.org/patients-visitors/cash-pricing"
              target="_blank"
              rel="noopener noreferrer">
              Good Shepherd Health System
            </a>
            &nbsp;(Longview, TX)
          </p>
          <p>
            <a
              href="https://monticellosurgery.com/pricing/"
              target="_blank"
              rel="noopener noreferrer">
              Monticello Community Surgery Center
            </a>{' '}
            (Charlottesville, VA)
          </p>
          <p>
            <a
              href="http://www.californiasurgeryprices.com/"
              target="_blank"
              rel="noopener noreferrer">
              Ocean Surgery Center
            </a>
            &nbsp;(Torrance, CA)
          </p>
          <p>
            <a href="http://prairiesurgicare.com/" target="_blank" rel="noopener noreferrer">
              Prairie SurgiCare
            </a>{' '}
            (Peoria, IL)
          </p>
          <p>
            <a href="http://www.regencyhealthnyc.com/" target="_blank" rel="noopener noreferrer">
              Regency Health
            </a>
            &nbsp;(New York NY)
          </p>
          <p>
            <a
              href="http://www.sworthopedic.com/Cash-Option-Pricing.html"
              target="_blank"
              rel="noopener noreferrer">
              Southwest Orthopedic Associates
            </a>
            &nbsp;(Fort Worth, TX)
          </p>
          <p>
            <a
              href="https://texasfreemarketsurgery.com/pricing/"
              target="_blank"
              rel="noopener noreferrer">
              Texas Free Market Surgery
            </a>{' '}
            (Austin, TX)
          </p>
          <p>
            <a
              href="http://www.pacificsurgicalwa.com/pricing/"
              target="_blank"
              rel="noopener noreferrer">
              Pacific Surgical Center
            </a>{' '}
            (Longview, WA) Many listed as{' '}
          </p>
          <p>
            <a
              href="http://www.kemptonpremierproviders.com/Procedures_and_Locations.php"
              target="_blank"
              rel="noopener noreferrer">
              Kempton Premier Providers
            </a>{' '}
            (various parts of Texas and Oklahoma)
          </p>
        </div>
      </div>
    </StandardPage>
  );
}
