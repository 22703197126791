import React from 'react';
import { Icon } from 'antd';
import ShareRow from './ShareRow';

const Footer = () => (
  <div className="p64 col ac jc inherit" style={{ backgroundColor: '#000000e0', padding: '63px' }}>
    <div className="wide row js as wrap wh70">
      <div className="col js as mh16 mt16 mw125 fl1">
        <span className="small wh30 mb8">Contact</span>
        <a href="mailto:team@dpcnation.org" className="mb8">
          <Icon type="mail" /> team@dpcnation.org
        </a>
      </div>
      <div className="col js as mh16 mt16 mw125 fl1">
        <span className="small wh30 mb8">Join the Facebook Group</span>

        <span>
          <a href="https://www.facebook.com/groups/dpcnation" className="clickable">
            <Icon type="facebook" theme="filled" /> DPC Nation
          </a>
        </span>
      </div>
      <div className="col js as mh16 mt16 mw125 fl1">
        <span className="small wh30 mb8">Share DPC with someone who needs it</span>
        <ShareRow />
      </div>
      <div className="col je ae mh16 mt16 mw125 fl1">
        <img
          src="/img/logo_white_small.png"
          style={{ width: '100px' }}
          alt="White DPC Nation Logo"
          title="White DPC Nation Logo"
        />
        <span className="wh30">©2019 DPC Nation</span>
      </div>
    </div>
  </div>
);

export default Footer;
